import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
const ProductsSwipeThree = () => (
  <Swiper
    spaceBetween={30}
    centeredSlides={true}
    effect={"fade"}
    autoplay={{
      delay: 3000,
      disableOnInteraction: false,
    }}
    pagination={true}
    modules={[Autoplay, EffectFade, Pagination]}
  >
    <SwiperSlide>
      {" "}
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396788/Page%204%20Products/500x600/NEW%2021032023/avp5050_af4ig2.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396788/Page%204%20Products/500x600/NEW%2021032023/avp5050_af4ig2.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396787/Page%204%20Products/500x600/NEW%2021032023/ave5877_yzjhjv.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396787/Page%204%20Products/500x600/NEW%2021032023/azr5763_ocbtqw.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528318/Page%204%20Products/500x600/azn5723_fb3ot0.jpg"
      />
    </SwiperSlide>{" "}
    
  </Swiper>
)

export default ProductsSwipeThree
