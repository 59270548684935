import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

const ProductsSwipeOne = () => (
  <Swiper
    spaceBetween={30}
    centeredSlides={true}
    effect={"fade"}
    autoplay={{
      delay: 3000,
      disableOnInteraction: false,
    }}
    pagination={true}
    modules={[Autoplay, EffectFade, Pagination]}
  >
    <SwiperSlide>
      {" "}
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528317/Page%204%20Products/500x600/azp5335_uocnah.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396789/Page%204%20Products/500x600/NEW%2021032023/BYJ0293_tdbeyt.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396787/Page%204%20Products/500x600/NEW%2021032023/azr5057_iqgbqa.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396789/Page%204%20Products/500x600/NEW%2021032023/BYJ0291_rmjhnd.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396790/Page%204%20Products/500x600/NEW%2021032023/BYJ0304_di0nid.jpg"
      />
    </SwiperSlide>
   
  </Swiper>
)

export default ProductsSwipeOne
