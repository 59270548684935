import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CenterLogo from "../components/CenterLogo"
import Logo from "../components/Logo"
import styled from "styled-components"
import LeftLogo from "../components/LeftLogo"
import PaddingLeftLogo from "../components/PaddingLeftLogo"
import ProductsSwipeOne from "../containers/Products/ProductsSwipeOne"
import ProductsSwipeTwo from "../containers/Products/ProductsSwipeTwo"
import ProductsSwipeThree from "../containers/Products/ProductsSwipeThree"
import Bg from "../components/Bg"
import ContactFormer from "../components/ContactFormer"
import H2 from "../components/H2"
const Relative = styled.div`
  position: relative;
`

const BgByj = styled.div`
  background-repeat: no-repeat;
  min-height: 60vh;
  background-size: cover;
  background-position: center;

  background-image: url("https://res.cloudinary.com/dh9k0znwt/image/upload/v1680073305/Page%204%20Products/1920x680-2_ts7biv.jpg");
  h1 {
    padding-top: 20vh;
  }
`


const ProductsPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="flex flex-wrap py-16 bg-white xl:pt-32 pt-24">
      <div className="item w-full h-auto text-center md:py-9 py-3  2xl:pt-4">
        <BgByj></BgByj>
      </div>
    </div>
    <div className="flex flex-wrap pb-16 bg-white">
      <div className="item w-full h-auto text-center md:py-9 py-3">
        <Relative>
          <CenterLogo>
            <Logo />
          </CenterLogo>
          <H2 className="2xl:text-6xl xl:text-6xl lg:text-6xl md:text-6xl text-4xl lg:pt-0  pt-4">
            Products
          </H2>
        </Relative>
      </div>
    </div>

    <div className="container mx-auto">
      <div className="flex flex-wrap">
        <div className="item  w-full md:w-full lg:w-1/2 h-full sm:order-1  md:order-1 lg:order-1">
          <img
            className="object-contain md:object-scale-down f-r"
            alt="a changer"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528316/Page%204%20Products/825x825/az5420_uo1hmh.jpg"
          />
        </div>
        <div className="item w-full md:w-full lg:w-1/2 h-auto order-2  md:order-2  lg:order-2   md:py-8 md:px-10 py-12 px-4">
          <div className="flex flex-wrap py-16 bg-white">
            <div className="item w-full h-auto ">
              <Relative>
                <LeftLogo>
                  <Logo />
                </LeftLogo>
                <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 lg:pt-2 pt-0 py-2">
                  Gold
                </H2>
              </Relative>
            </div>
          </div>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            All gold products are produced with great care and with high-quality
            hand polish. We are using 8K to 18 K yellow, white and rose gold.
            Stones can be wax or hand set.
          </p>
        </div>
        <div className="item  w-full md:w-full lg:w-1/2 h-auto order-4  md:order-4 lg:order-3 md:py-8 md:px-10 py-12 px-4">
          <PaddingLeftLogo />
          <Relative>
            <LeftLogo>
              <Logo />
            </LeftLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 pt-0 lg:pt-2 py-2">
              silver{" "}
            </H2>
          </Relative>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            All silver products are produced with the same care and attention to
            detail as our gold production.
          </p>
        </div>

        <div className="item  w-full md:w-full lg:w-1/2 h-full order-3  md:order-3 lg:order-4">
          <img
            className="object-contain md:object-scale-down f-l"
            alt="a changer"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679307231/Page%204%20Products/825x825/BYJ0050-BYJ0052_825x825_v8hpcc.jpg"
          />
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="item  w-full md:w-full lg:w-1/2 h-full sm:order-5  md:order-5 lg:order-5">
          <img
            className="object-contain md:object-scale-down f-r"
            alt="a changer"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528316/Page%204%20Products/825x825/big_twisted_hoop_h9cly7.jpg"
          />
        </div>
        <div className="item w-full md:w-full lg:w-1/2 h-auto order-6  md:order-6  lg:order-6 md:py-8 md:px-10 py-12 px-4">
          <div className="flex flex-wrap  bg-white">
            <div className="item w-full h-auto  md:py-9 py-3">
              <Relative>
                <LeftLogo>
                  <Logo />
                </LeftLogo>
                <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 lg:pt-2 py-2 pt-0">
                  Brass{" "}
                </H2>
              </Relative>
            </div>
          </div>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            We produce high quality brass jewelry with specialized plating
            standards for brands.
          </p>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-wrap py-14 ">
          <div className="flex flex-wrap overflow-hidden lg:-mx-2 xl:-mx-2 md:pt-20 pt-18">
            <div className="w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
              <ProductsSwipeOne />
              <h3 className="text-center text-3xl py-6 md:py-12">Gold</h3>
            </div>
            <div className="w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
              <ProductsSwipeTwo />
              <h3 className="text-center text-3xl py-6 md:py-12">Silver</h3>
            </div>
            <div className="w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
              <ProductsSwipeThree />
              <h3 className="text-center text-3xl py-6 md:py-12">Brass</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bg>
      <ContactFormer />
    </Bg>
  </Layout>
)

export default ProductsPage
